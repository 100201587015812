import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import loading_img from "../../../../images/profile/loading.gif";
import { useParams, Router, useLocation } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import nav_data from '../../../layouts/nav/NavData';
import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


const location = useLocation();
const urlSearchParams = new URLSearchParams(location.search);
const provider = urlSearchParams.get('provider');
let  providerGet;
if (provider !== undefined && provider !== null && provider !== '') {
   providerGet = provider;
}else{
   providerGet = "";
}
 
 

   const { id } = useParams();
   const { BaseUrl } = GlobalSet();
   const [homeitem, setHomeItem] = useState([]); 
   const [homeCasino, setCasino] = useState([]);   
   const var_casino= nav_data.casino;
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
 
 
  const [visibleItems, setVisibleItems] = useState(80); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          slug: "slot",
          search: search,
          provider: providerGet,
        };

          console.log( data);
        const response = await axios.post('/bonus-filters', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setcount_game(response.data.count_game);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      // Clear data if the query is empty
      setHomeItem([]);
      setCasino([]);
      setLoading(false);
    }

    // Cleanup function to cancel the request if the component unmounts
    return () => source.cancel();
  }, [id, search, loading]);

  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 80); // Adjust the number as needed
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card>

                <div>
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>


              <Card.Body className="p-2" >
              <div className="form-inline">
                <div className="row">
                   {homeitem.slice(0, visibleItems).map((ele) => (
                   <div className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={ele.id}>
                    <div className="card project-boxed">
                      <div className="img-bx">
                        <img
                          src={ele.image}
                          alt=""
                          className="me-3 card-list-img w-100"
                          width="130"
                        />
                      </div>
                      <div className="card-header align-items-start">
                        <div>
                          <p className="fs-14 mb-2 text-primary">{ele.id}</p>
                          <h6 className="fs-18 font-w500 mb-3">
                            <a className="text-black user-name" href="#">
                              {ele.name}
                            </a>
                          </h6>
                        </div>
                      </div>
                      <div className="card-body p-0 pb-3">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <span className="mb-0 title">Deadline Date</span> :
                            <span className="text-black ms-2">{ele.slug}</span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Provider</span> :
                            <span className="text-black ms-2">{ele.slug}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
              </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}

              </Card.Body>


            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;