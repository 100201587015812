import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import loading_img from "../../../images/profile/loading.gif";
import { useHistory, useLocation } from "react-router-dom";

const BetTechComponent = () => {


  return (
    <div>

         <div className="d-flex justify-content-center align-items-center vh-100">
            <img src={loading_img} alt="" className="me-3 rounded" width={75} />
         </div>
    </div>
  );
};

export default BetTechComponent;
