import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ButtonGroup, Button, Col } from 'react-bootstrap';

const Home = () => {


  return (
    <div className="d-xl-block" style={{ marginTop: '-36px' }}>
      <div className="row">
        <div className="card p-0 mb-0" style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
              <span className="fa fa-spinner fa-spin text-black" style={{ fontSize: "50px" }}></span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
